<template>
  <div>
    <PageConversationCS />
  </div>
</template>

<script>
import PageConversationCS from "@/plugins/prepChat/components/cs/PageConversationCS.vue";
export default {
  components: {
    PageConversationCS,
  },
};
</script>

<style></style>
